<template>
  <div class="d-flex flex-column">
    <v-row class="mt-3 mb-6" justify="end">
      <v-col cols="6">
        <div class="d-flex align-center" role="group" aria-labelledby="benefits-chip-group-label">
          <span id="benefits-chip-group-label" class="mr-4 body-2 grey-02--text">Filter by:</span>
          <ChipFilterModified
            v-model="selectedBenefitCategories"
            name="Benefit Category"
            heading="Benefit Category"
            :items="benefitCategoriesFilterCheckboxes"
          />
        </div>
      </v-col>
      <v-col class="d-flex justify-end" cols="6">
        <AdsButton buttonText="Upload CSV" icon="mdi-upload-outline" @click="openModal" />
      </v-col>
      <v-col cols="12">
        <v-skeleton-loader v-if="isLoading" type="heading, table-row-divider, table-row@10, table-tfoot" />
        <AdsDataTable v-else class="benefits-table" :headers="tableHeaders" :items="filteredBenefits">
          <template #item.name="{ item }">
            <p class="body-1 mb-0">
              {{ item.name }}
            </p>
            <p v-if="item.description" class="body-2 mb-0">
              {{ item.description }}
            </p>
          </template>
          <template #item.actions="{ item }">
            <div class="d-flex justify-start">
              <v-icon color="primary-red" @click="openDeleteDialog(item.PK)"> mdi-delete-outline </v-icon>
              <v-icon v-if="item.variants?.length > 0" class="ml-4" @click="viewVariants(item.PK)">
                mdi-chevron-right
              </v-icon>
            </div>
          </template>
        </AdsDataTable>
      </v-col>
    </v-row>
    <UpdateBenefitsDialog
      :openDialog="openUpdateBenefitsDialog"
      :selectedBenefitSchema="selectedBenefitSchema"
      @close="handleCloseModal"
    />
    <Dialog
      :openDialog="deleteDialog"
      icon="mdi-delete-alert"
      title="Please confirm benefit deletion"
      displayCloseBtn
      maxWidth="500px"
      @close="cancelBenefitDeletion"
    >
      <template #title>
        <v-card-title class="d-flex justify-space-between">
          <div class="delete-dialog__title title grey-01--text">
            <v-avatar size="40">
              <v-icon> mdi-delete-outline </v-icon>
            </v-avatar>
            Delete benefit
          </div>
        </v-card-title>
      </template>
      <template #text>
        <div class="my-10 px-16 text-center">
          Are you sure you want to delete
          {{ selectedBenefit?.details.name }}?
        </div>
      </template>
      <template #actions>
        <v-card-actions class="pb-6 px-6 justify-end">
          <AdsButton class="mr-4" secondary buttonText="Cancel" @click="cancelBenefitDeletion" />
          <AdsButton
            class="white--text"
            primary
            color="primary-red"
            buttonText="Delete"
            icon="mdi-delete-outline"
            @click="deleteBenefit"
          />
        </v-card-actions>
      </template>
    </Dialog>
  </div>
</template>

<script>
import { AdsDataTable, AdsButton, Dialog } from '@nswdoe/doe-ui-core';

import { mapActions, mapMutations, mapState } from 'vuex';

import ChipFilterModified from '@/components/ChipFilterModified';

import UpdateBenefitsDialog from '@/views/Admin/Benefits/UploadBenefitsDialog.vue';

import { clone } from 'ramda';

export default {
  name: 'Benefits',
  components: {
    AdsDataTable,
    AdsButton,
    ChipFilterModified,
    Dialog,
    UpdateBenefitsDialog,
  },
  data() {
    return {
      deleteDialog: false,
      openUpdateBenefitsDialog: false,
      tableHeaders: [
        {
          text: 'Benefit',
          value: 'name',
        },
        {
          text: 'Benefit category',
          value: 'benefitCategory',
        },
        {
          text: 'Code',
          value: 'code',
        },
        {
          text: 'Total schools',
          value: 'totalSchools',
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          align: 'center',
        },
      ],
      benefitSchemas: [
        {
          title: 'Benefit Details',
          type: 'basic',
        },
        {
          title: 'Misc Benefits',
          type: 'misc',
        },
        {
          title: 'Relocation Subsidy',
          type: 'school_code',
        },
        {
          title: 'RRW Benefits List',
          type: 'school_code',
        },
        {
          title: 'T510J',
          type: 'T510J',
        },
        {
          title: 'T510K',
          type: 'T510K',
        },
        {
          title: 'TEPS School Benefit',
          type: 'school_code',
        },
        {
          title: 'ZPYC Allowance',
          type: 'zpyc',
        },
      ],
      benefitCategoriesFilterCheckboxes: [
        {
          id: 'Major Financial Benefit',
          name: 'Major Financial Benefit',
        },
        {
          id: 'Professional Benefit',
          name: 'Professional Benefit',
        },
        {
          id: 'Available Additional Benefit',
          name: 'Available Additional Benefit',
        },
      ],
      selectedBenefitSchema: null,
      selectedBenefitCategories: [],
    };
  },
  computed: {
    ...mapState(['isLoading']),
    ...mapState('moduleAdmin', ['benefits', 'selectedBenefit']),
    tableRowsContent() {
      return this.benefits.map((benefit) => {
        const { details, variants } = benefit;
        return {
          PK: details.PK,
          code: details.benefitCode,
          name: details.name,
          description: details.description ? this.removeParenthesis(details.description) : null,
          benefitCategory: details.benefitCategory,
          totalSchools: details.totalSchools,
          variants,
        };
      });
    },
    filteredBenefits() {
      let filteredBenefits = this.tableRowsContent;

      // Filter by benefit category
      if (this.selectedBenefitCategories.length > 0) {
        filteredBenefits = filteredBenefits.filter((benefit) =>
          this.selectedBenefitCategories.includes(benefit.benefitCategory)
        );
      }

      return filteredBenefits;
    },
  },
  methods: {
    ...mapActions('moduleAdmin', ['getBenefits', 'createUpdateDeleteBenefit']),
    ...mapMutations('moduleAdmin', ['SET_SELECTED_BENEFIT', 'RESET_SELECTED_BENEFIT']),
    openModal() {
      this.openUpdateBenefitsDialog = true;
    },
    handleCloseModal() {
      this.openUpdateBenefitsDialog = false;
      this.selectedBenefitSchema = null;
    },
    selectBenefit(PK) {
      const benefit = this.benefits.find((benefit) => benefit.details.PK === PK);
      if (benefit) this.SET_SELECTED_BENEFIT(benefit);
    },
    viewVariants(PK) {
      this.selectBenefit(PK);
      this.$router.push({
        name: 'variants',
        params: { benefitCode: this.selectedBenefit.details.benefitCode },
      });
    },
    openDeleteDialog(PK) {
      this.selectBenefit(PK);
      this.deleteDialog = true;
    },
    cancelBenefitDeletion() {
      this.deleteDialog = false;
      this.RESET_SELECTED_BENEFIT();
    },
    async deleteBenefit() {
      const benefitToSubmit = clone(this.selectedBenefit);
      const { details } = benefitToSubmit;
      const updatedDetails = {
        ...details,
        active: false,
      };
      await this.createUpdateDeleteBenefit({
        formData: {
          ...benefitToSubmit,
          details: updatedDetails,
        },
        type: 'delete',
      });
      this.deleteDialog = false;
      this.RESET_SELECTED_BENEFIT();
      await this.getBenefits();
    },
    selectBenefitSchemaForUpload(schemaType) {
      this.selectedBenefitSchema = schemaType;
      this.openModal();
    },
    removeParenthesis(str) {
      if (!str) return '';
      let textWithoutParenthesis;
      if (str.charAt(0) === '(' && str.charAt(str.length - 1) === ')') {
        textWithoutParenthesis = str.slice(1, -1);
      } else textWithoutParenthesis = str;
      return textWithoutParenthesis.charAt(0).toUpperCase() + textWithoutParenthesis.slice(1);
    },
  },
  async created() {
    await this.getBenefits();
  },
};
</script>

<style lang="scss" scoped>
:deep(.benefits-table tr) {
  height: 60px;
}

:deep.v-card__title {
  padding: 24px 10px 0 24px;
  margin-bottom: 18px;
  color: $ads-primary-blue !important;

  .v-avatar {
    background-color: rgba(215, 21, 58, 0.12) !important;
    margin-right: 6px;

    i {
      color: $ads-primary-red;
    }
  }
}
</style>
