<template>
  <div>
    <v-menu
      v-if="$vuetify.breakpoint.smAndUp"
      v-model="show"
      offset-y
      bottom
      attach
      transition="scale-transition"
      :left="alignRight"
      :nudge-bottom="8"
      :origin="alignRight ? 'top right' : 'top left'"
      :min-width="minWidth"
      :max-height="maxHeight"
    >
      <template
        #activator="{ on, attrs }"
      >
        <Activator
          v-on="on"
          v-bind="attrs"
          v-model="show"
          :selected="selected"
          :name="name"
          :heading="heading"
          :disabled="disabled"
          :mandatory="type === TYPE.RADIO ? mandatory : false"
          :id="chipId"
          @clear:filter="clearFilter"
        />
      </template>
      <Content
        v-bind="$attrs"
        v-on="$listeners"
        :items="items"
        :heading="heading"
        :multiLevel="multiLevel"
        :type="type"
        :mandatory="type === TYPE.RADIO ? mandatory : false"
        :selectableParent="selectableParent"
        :hideArrows="hideArrows"
        :clearLink="clearLink"
        :clearLinkText="clearLinkText"
        @clear:filter="clearFilter"
        @close:menu="show = false"
      />
    </v-menu>
    <!--    Mobile version-->
    <v-dialog
      v-if="$vuetify.breakpoint.xsOnly"
      v-model="show"
    >
      <template
        #activator="{ on, attrs }"
      >
        <Activator
          v-on="on"
          v-bind="attrs"
          v-model="show"
          :selected="selected"
          :name="name"
          :heading="heading"
          :disabled="disabled"
          :mandatory="mandatory"
          :id="chipId"
          @clear:filter="clearFilter"
        />
      </template>
      <Content
        v-bind="$attrs"
        v-on="$listeners"
        :items="items"
        :heading="heading"
        :multiLevel="multiLevel"
        :type="type"
        :mandatory="mandatory"
        :selectableParent="selectableParent"
        :hideArrows="hideArrows"
        :clearLink="clearLink"
        :clearLinkText="clearLinkText"
        @clear:filter="clearFilter"
        @close:menu="show = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import Activator from './Activator';
import Content from './Content';
import {TYPE} from './constants';

// ChipFilter's v-model get selected values from the checkbox or radio group
export default {
  name: 'ChipFilter',
  inheritAttrs: false,
  components: {
    Activator,
    Content
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    heading: {
      type: String,
      default: 'Show only'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: TYPE.CHECKBOX,
      validator: function (value) {
        // only allow checkbox or radio
        return [TYPE.CHECKBOX, TYPE.RADIO].indexOf(value) !== -1;
      }
    },
    multiLevel: {
      type: Boolean,
      default: false,
    },
    selectableParent: {
      type: Boolean,
      default: true
    },
    hideArrows: {
      type: Boolean,
      default: false
    },
    mandatory: {
      type: Boolean,
      default: false,
    },
    clearLink: {
      type: Boolean,
      default: false
    },
    clearLinkText: {
      type: String,
      default: 'Clear filter'
    },
    alignRight: {
      type: Boolean,
      default: false
    },
    minWidth: {
      type: Number,
      default: 280
    },
    maxHeight: {
      type: Number,
      default: 480
    }
  },
  data() {
    return {
      TYPE,
      // boolean v-model controlling if Content is visible or hidden.
      show: false,
    }
  },
  methods: {
    clearFilter() {
      if (this.type === TYPE.RADIO) {
        this.$listeners.input && this.$listeners.input('');
      } else if (this.type === TYPE.CHECKBOX) {
        this.$listeners.input && this.$listeners.input([]);
      }
    }
  },
  computed: {
    selected() {
      if (this.type === TYPE.RADIO) {
        return !!(this.$attrs.value && this.$attrs.value !== '');
      } else if (this.type === TYPE.CHECKBOX) {
        return !!(this.$attrs.value && this.$attrs.value.length > 0);
      }
      return this.$attrs.value != null;
    },
    chipId() {
      return 'chip-' +
        this.name.replace(/[^a-zA-Z ]/g, '')
          .split(' ')
          .join('')
          .toLowerCase();
    }
  },
  watch: {
    mandatory: {
      immediate: true,
      handler(val) {
        if (val && this.type === TYPE.RADIO) {
          this.$listeners.input(this.items[0]);
        }
      }
    }
  },
}
</script>

<style lang="scss" scoped>
// fix issue with menu not displaying after adding 'attach' attribute
.v-menu {
  display: block;
}

// dialog open at the top, full screen width on mobile portrait
::v-deep.v-dialog__content {
  align-items: start;

  &.v-dialog__content--active {
    .v-dialog {
      &.v-dialog--active {
        margin: 0;
        border-radius: 0;
      }
    }
  }
}
</style>
