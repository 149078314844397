<template>
  <v-container fluid style="height: -webkit-calc(100vh - 118px); overflow-y: auto; background-color: #eaeaea">
    <v-row class="ma-0">
      <v-col :class="mobile ? 'ml-1 mt-2' : 'ml-10 pl-4 mt-5'" class="pa-0">
        <AdsButton class="backbutton pa-0 primary--text" tertiary @click="goBack">
          <v-icon class="mr-1"> arrow_back</v-icon>
          Back
        </AdsButton>
      </v-col>
    </v-row>
    <v-row class="ma-0" style="width: 100%">
      <v-col :class="mobile ? 'ma-1' : 'ma-10'" class="mt-0">
        <FocusTrap v-if="showAllSchoolDialog" isActive :returnFocusOnStop="false">
          <Dialog
            :displayCloseBtn="true"
            :fullscreen="mobile"
            :openDialog="showAllSchoolDialog"
            icon="add_circle_outline"
            maxWidth="70%"
            title="Add school"
            @close="showAllSchoolDialog = false"
            @click:outside="showAllSchoolDialog = false"
          >
            <template slot="text">
              <div class="pa-0">
                <CompareSchool :results="results.schools" @compareSchool="addSelectedSchool" />
              </div>
            </template>
          </Dialog>
        </FocusTrap>
        <div class="d-flex justify-space-between align-end mb-8">
          <div>
            <h2 class="mb-3 display-1">Compare</h2>
            <p class="mb-0">
              Compare the range of benefits and incentives across NSW public schools. You may compare up to 5 schools.
            </p>
          </div>
          <div>
            <AdsButton secondary>
              <v-icon class="mt-1 pr-1 material-icons-outlined"> download</v-icon>
              <download-csv :data="csvData"> Download CSV</download-csv>
            </AdsButton>
          </div>
        </div>
        <p class="my-3 body-2">
          <strong>Please note</strong>
          that the values provided by the calculator are indicative only.
        </p>
        <div style="overflow-x: auto">
          <table>
            <thead>
              <tr>
                <th class="px-6 py-8 schoolTitle" style="background-color: #041e42; color: #6cace4">Schools</th>
                <th
                  v-for="(school, index) in schools"
                  :key="`${school.schoolCode}-${index}`"
                  class="px-4 py-8 title"
                  style="background-color: #041e42; color: white"
                >
                  <div class="d-flex justify-space-between">
                    <button class="pr-2" style="cursor: default">
                      {{ school.schoolFullName }}
                    </button>
                    <div class="removeSchool">
                      <AdsButton
                        ref="schoolItems"
                        :aria-label="`Remove ${school.schoolFullName}`"
                        button-text=""
                        icon="remove_circle_outline"
                        primary
                        style="background-color: #041e42; color: #6cace4; border-color: #6cace4"
                        @click="removeFromCompare(school, index)"
                      ></AdsButton>
                    </div>
                  </div>
                </th>
                <th
                  v-for="col in freeCols"
                  :key="col"
                  class="px-4 py-8 title"
                  style="background-color: #041e42; color: #c8dcf0"
                >
                  <div style="width: 100%; text-align: center">
                    <AdsButton
                      ref="addSchool"
                      aria-label="Add School"
                      button-text="Add School"
                      dark
                      icon="add_circle_outline"
                      secondary
                      style="background-color: #041e42; color: #6cace4 !important; border-color: #6cace4"
                      @click="addSchoolForComparison()"
                    ></AdsButton>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="title" colspan="100%" style="background-color: #eaeaea; border-bottom: 1px solid #ccc">
                  <AdsExpansionPanel v-model="expansionPanelModel" :items="expansionItems" multiple>
                    <template v-for="(expansionItem, index) in expansionItems" #[`content${index+1}`]>
                      <template>
                        <div
                          v-if="!categoryBenefitsInSchool(expansionItem.category, schools, allBenefits)"
                          :key="`expansionItem-${index}`"
                          class="ma-0 pa-0 d-flex flex-row"
                        >
                          <div class="pl-6 py-3 d-flex">
                            <div class="body-1 mb-0">No benefits or allowances available in this category</div>
                          </div>
                        </div>
                      </template>
                      <template v-for="(benefit, benefitIndex) in allBenefits">
                        <div
                          v-if="
                            expansionItem.category === benefit.benefitCategory && benefitInSchools(schools, benefit)
                          "
                          :key="`benefit-${benefit.name}-${index}-${benefitIndex}`"
                          class="ma-0 pa-0 d-flex flex-row"
                        >
                          <div style="width: 16.66%; border-right: 1px solid #ccc">
                            <div class="pa-6 d-flex flex-column align-self-start">
                              <div class="benefitsTitle mb-1">
                                {{ benefit.name }}
                              </div>
                              <div
                                v-if="benefit.description"
                                class="body-2"
                                style="color: #495054"
                                v-html="marked(benefit.description)"
                              ></div>
                            </div>
                          </div>
                          <div class="d-flex flex-row align-content-center" style="width: 83.34%">
                            <div
                              v-for="school in schools"
                              :key="`school-${school.schoolCode}-${benefit.name}-${index}-${benefitIndex}`"
                              class="pa-6 d-flex flex-column"
                              style="width: 19.9904%; height: 100%; border-right: 1px solid #ccc"
                            >
                              <div class="d-flex flex-row" style="height: 100%">
                                <div class="mr-4">
                                  <v-icon
                                    v-if="
                                      findSchoolBenefit(school, benefit) && findSchoolBenefit(school, benefit) !== 'No'
                                    "
                                    class="green-tick"
                                  >
                                    check
                                  </v-icon>
                                  <v-icon v-else class="red-cross"> close</v-icon>
                                </div>
                                <div class="d-flex flex-column">
                                  <div>
                                    <span
                                      v-if="benefit.valueDenomination?.includes('$')"
                                      class="font-weight-bold"
                                      v-html="`${benefit.valueDenomination}`"
                                    />
                                    <span class="font-weight-bold" v-html="findSchoolBenefit(school, benefit)" />
                                    <span
                                      v-if="benefit.valueDenomination === '%'"
                                      class="font-weight-bold"
                                      v-html="`${benefit.valueDenomination}`"
                                    />
                                    <span
                                      v-else-if="
                                        !!benefit.valueDenomination && !benefit.valueDenomination?.includes('$')
                                      "
                                      class="font-weight-bold"
                                      v-html="
                                        findSchoolBenefit(school, benefit) > 1 || !findSchoolBenefit(school, benefit)
                                          ? ` ${benefit.valueDenomination}s`
                                          : ` ${benefit.valueDenomination}`
                                      "
                                    />
                                  </div>
                                  <div class="body-2" style="color: #495054">
                                    {{ benefit.valueDescription }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </template>
                  </AdsExpansionPanel>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import CompareSchool from '@/components/SearchResults/AddSchool.vue';
import { AdsButton, AdsExpansionPanel, Dialog, FocusTrap } from '@nswdoe/doe-ui-core';
import { marked } from 'marked';
import { BENEFIT_CATEGORIES } from '@/constants';
import { findCategoryBenefits, formatValue } from '@/helpers/utils';

export default {
  components: {
    AdsExpansionPanel,
    Dialog,
    AdsButton,
    CompareSchool,
    FocusTrap,
  },
  data() {
    return {
      expansionPanelModel: [0],
      expansionItems: [
        {
          title: 'Major Financial Benefits',
          category: BENEFIT_CATEGORIES.major,
        },
        {
          title: 'Professional Benefits',
          category: BENEFIT_CATEGORIES.professional,
        },
        {
          title: 'Available Additional Allowances',
          category: BENEFIT_CATEGORIES.additional,
        },
      ],
      showAllSchoolDialog: false,
      scrollerHeight: '90vh',
    };
  },
  computed: {
    ...mapGetters('moduleResults', ['results']),
    ...mapGetters(['compareList']),

    mobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    schoolResults() {
      if (Array.isArray(this.results) && this.results.length > 0) {
        return this.results;
      }
      return [];
    },
    schools() {
      return (
        this.compareList?.map((school) => {
          const benefitItems = findCategoryBenefits(this.results.benefitDetails, school.benefits);
          return {
            ...school,
            benefits: {
              ...school.benefits,
              benefitItems,
            },
          };
        }) ?? []
      );
    },
    csvData() {
      // Create an array for each benefit, with each school's value for that benefit
      const csvData = this.unifiedBenefits.map((benefit) => {
        let row;
        if (benefit.description) {
          row = { benefit: `${benefit.name} ${benefit.description}` };
        } else {
          row = { benefit: `${benefit.name}` };
        }

        this.compareList.forEach((school) => {
          const schoolBenefit = school.benefits.benefitItems.find(
            (schoolBenefit) => schoolBenefit.benefitCode === benefit.benefitCode
          );
          let benefitFinalValue;
          if (schoolBenefit) {
            let formattedValue;
            if (isNaN(+schoolBenefit?.value)) {
              formattedValue = schoolBenefit?.value ?? '';
            } else {
              const formattedValueAsNumber = +schoolBenefit.value;
              formattedValue = formattedValueAsNumber.toLocaleString();
            }
            switch (schoolBenefit.valueDenomination) {
              case '$':
                benefitFinalValue = `${schoolBenefit.valueDenomination}${formattedValue}`;
                break;
              case '%':
                benefitFinalValue = `${formattedValue}${schoolBenefit.valueDenomination}`;
                break;
              default:
                benefitFinalValue = schoolBenefit.valueDenomination
                  ? `${formattedValue} ${schoolBenefit.valueDenomination}`
                  : formattedValue;
            }
          }
          row[school.schoolFullName] = benefitFinalValue ?? null;
        });
        return row;
      });

      return csvData;
    },
    freeCols() {
      return 5 - this.schools.length;
    },
    unifiedBenefits() {
      const unifiedBenefits = this.$store.state.compareList.flatMap((school) => {
        return school.benefits.benefitItems;
      });
      const unifiedBenefitsCodes = unifiedBenefits.map((benefit) => benefit.benefitCode);
      const allBenefits = unifiedBenefitsCodes.map((benefitCode) => {
        return this.results.benefitDetails.find((benefit) => {
          return benefit.benefitCode === benefitCode;
        });
      });
      return [...new Set(allBenefits)];
    },
    allBenefits() {
      const uniqueBenefitSet = new Set();
      for (const benefitDetail of this.results.benefitDetails) {
        uniqueBenefitSet.add(
          JSON.stringify({
            name: benefitDetail.name,
            description: benefitDetail.description,
            benefitCategory: benefitDetail.benefitCategory,
            valueDescription: benefitDetail.valueDescription,
            valueDenomination: benefitDetail.valueDenomination,
          })
        );
      }
      return [...uniqueBenefitSet]
        .map((item) => JSON.parse(item))
        .sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          if (a.name?.toLowerCase() > b.name?.toLowerCase()) {
            return 1;
          }
          return 0;
        });
    },
  },
  methods: {
    marked,
    goBack() {
      this.$router.push('/');
    },
    removeFromCompare(school, index) {
      this.$store.dispatch('removeFromCompare', school);
      this.$nextTick(() => {
        const afterLastIndex = this.schools.length - 1;
        let focusIndex;
        switch (true) {
          case index <= afterLastIndex:
            focusIndex = index;
            break;
          case index > afterLastIndex:
            focusIndex = afterLastIndex;
            break;
          case index === 0:
          default:
            focusIndex = 0;
            break;
        }
        if (this.schools.length) {
          this.$refs.schoolItems[focusIndex].$el.focus();
        } else {
          this.$refs.addSchool[0].$el.focus();
        }
      });
    },
    addSchoolForComparison() {
      this.showAllSchoolDialog = true;
    },
    addSelectedSchool() {
      this.showAllSchoolDialog = false;
      this.$nextTick(() => {
        if (this.$refs.addSchool.length) {
          this.$refs.addSchool[0].$el.focus();
        } else {
          this.$refs.schoolItems.slice(-1)[0].$el.focus();
        }
      });
    },
    categoryBenefitsInSchool(category, schools, benefits) {
      return benefits.some(
        (benefit) => benefit.benefitCategory === category && this.benefitInSchools(schools, benefit)
      );
    },
    benefitInSchools(schools, benefit) {
      return schools.some(
        (item) =>
          item.benefits.benefitItems
            .filter((filtered) => filtered.benefitCategory === benefit.benefitCategory)
            .find((benefitItem) => {
              return (
                benefitItem.name === benefit.name &&
                benefitItem.description === benefit.description &&
                benefitItem.benefitCategory === benefit.benefitCategory &&
                benefitItem.valueDescription === benefit.valueDescription &&
                benefitItem.valueDenomination === benefit.valueDenomination
              );
            })?.value
      );
    },
    findSchoolBenefit(school, benefit) {
      const found = school.benefits.benefitItems.find(
        (benefitItem) => benefitItem.name === benefit.name && benefitItem.value !== undefined
      )?.value;
      switch (true) {
        case benefit.valueDenomination?.includes('$'):
          return found ? formatValue(found) : 0;
        case benefit.valueDenomination === '%' || !!benefit.valueDenomination:
          return found || 0;
        case !benefit.valueDenomination:
          return found || 'No';
        default:
          return '';
      }
    },
    standardiseBenefitsForCSV(data) {
      let benefitsArray = [];

      for (let category in data) {
        for (let benefit of data[category].benefits) {
          let newBenefit = {};

          // Combine benefit and extraLine values
          if (benefit.extraLine) {
            newBenefit.benefit = `${benefit.benefit} - ${benefit.extraLine}`;
          } else {
            newBenefit.benefit = benefit.benefit;
          }

          // Fill in other values, or null if not provided
          newBenefit.isIncluded = Object.prototype.hasOwnProperty.call(benefit, 'isIncluded')
            ? benefit.isIncluded
              ? 'Yes'
              : 'No'
            : null;

          if (Object.prototype.hasOwnProperty.call(benefit, 'value')) {
            if (typeof benefit.value === 'number') {
              newBenefit.value = new Intl.NumberFormat('en-AU', {
                style: 'currency',
                currency: 'AUD',
              }).format(benefit.value);
            } else {
              newBenefit.value = benefit.value;
            }
          } else {
            newBenefit.value = null;
          }

          newBenefit.extraValueLine = Object.prototype.hasOwnProperty.call(benefit, 'extraValueLine')
            ? benefit.extraValueLine
            : null;

          benefitsArray.push(newBenefit);
        }
      }

      return benefitsArray;
    },
  },
};
</script>

<style scoped>
.removeSchool ::v-deep .v-btn:not(.v-btn--round).v-size--default {
  min-width: 24px !important;
  width: 24px !important;
  padding: 0 !important;
}

.removeSchool ::v-deep .pl-2 {
  padding-left: 0 !important;
}

::v-deep .subtitle-1 {
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 25px !important;
}

.schoolTitle {
  font-size: 24px !important;
}

.backbutton::v-deep.v-btn:not(.v-btn--round).v-size--default {
  min-width: unset !important;
}

.benefitsTitle {
  font-family: Public Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  color: #22272b;
}

table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ccc;
  border-radius: 20px;
  background-color: white;
}

th,
td {
  text-align: left;
  border-right: 1px solid #ccc;
  width: 16.66%;
}

td {
  vertical-align: top;
}

.green-tick {
  color: green;
}

.red-cross {
  color: red;
}

.benefit-description {
  overflow-wrap: break-word;
}
</style>
